import React from "react";

interface ContactUsHeaderProps {
  title: {
    line: string;
    "lineDecoration-1": string;
    "lineDecoration-2": string;
  };
}
const ContactUsHeader = ({ title }: ContactUsHeaderProps) => {
  return (
    <>
      <span className="relative mr-2 inline-flex w-fit text-site-text-32-38 font-normal capitalize text-site-text-blue-1 md:text-site-text-56-67">
        {title["line"] || "Interested In"}
      </span>
      <span className="relative z-0 mb-3 mr-2 inline-flex w-fit text-site-text-32-38 font-normal capitalize text-site-text-blue-1 before:absolute before:bottom-[-10px] before:left-0 before:-z-1 before:h-[11px] before:w-full before:bg-[url('/images/site/ContactUs/decor_line.svg')] before:bg-cover before:bg-no-repeat md:text-site-text-56-67 md:before:bottom-[-12px] md:before:h-[19px]">
        {title["lineDecoration-1"] || "Working"}
      </span>
      <br className="hidden md:block xl:hidden" />
      <span className="relative z-0 mr-2 inline-flex w-fit text-site-text-32-38 text-site-text-blue-1 before:absolute before:-top-1.5 before:right-[-23px] before:-z-1 before:h-[20px] before:w-[28px] before:bg-[url('/images/site/ContactUs/decor.svg')] before:bg-contain before:bg-no-repeat md:text-site-text-56-67 md:before:-top-1.5 md:before:h-[32px]">
        {title["lineDecoration-2"] || "With Us?"}
      </span>
    </>
  );
};

export default ContactUsHeader;
