import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import Image from "next/image";
import React, { ReactNode, useEffect, useRef } from "react";

interface ModalProps {
  children: ReactNode;
  classWrapper?: string;
  classModal?: string;
  imageLogo?: boolean;
}

export const Modal = ({ children, classWrapper, classModal, imageLogo = false }: ModalProps) => {
  const { openModal, setOpenModal, closingModal, setClosingModal } = useSiteContext();
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpenModal(false);
    return () => {
      setOpenModal(false);
    };
  }, [setOpenModal]);

  const handlerClose = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (
      (modal.current && !modal.current.contains(event.target as Node)) ||
      event.currentTarget.classList.contains("closeModal")
    ) {
      closeModalWindow();
    }
  };
  const closeModalWindow = () => {
    setClosingModal(true);
    setTimeout(() => {
      setOpenModal(false);
    }, 300);
  };
  if (!openModal) return null;

  return (
    <>
      <div
        className={classNames(
          "fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300",
          closingModal ? "-z-1 opacity-0" : "z-[100] opacity-100"
        )}
        onClick={(event) => handlerClose(event)}
      />
      <div
        onClick={(event) => handlerClose(event)}
        className={classNames(
          "fixed inset-0 flex items-center justify-center transition-transform duration-300",
          closingModal ? "-z-1 scale-95 opacity-0" : "z-[101] scale-100 opacity-100",
          classWrapper
        )}>
        <div ref={modal} className={classNames("overflow-y-auto", classModal)}>
          <div className="relative flex justify-center">
            {imageLogo && <Image src="/images/site/SimpleTherapy.svg" alt="logo" width="157" height="24" />}
            <button
              className="closeModal group absolute -right-[5px] -top-[16px] lg:-right-3.5 lg:-top-3.5"
              onClick={(event) => handlerClose(event)}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  className="transition-all duration-300 group-hover:fill-current group-hover:text-red"
                  d="M22.6903 20.435C22.9897 20.7344 23.1579 21.1405 23.1579 21.5639C23.1579 21.9873 22.9897 22.3934 22.6903 22.6928C22.3909 22.9922 21.9848 23.1604 21.5614 23.1604C21.1379 23.1604 20.7319 22.9922 20.4325 22.6928L12.0002 14.2579L3.56526 22.6901C3.26586 22.9895 2.85978 23.1577 2.43636 23.1577C2.01294 23.1577 1.60686 22.9895 1.30745 22.6901C1.00805 22.3907 0.839844 21.9846 0.839844 21.5612C0.839844 21.1378 1.00805 20.7317 1.30745 20.4323L9.74237 12L1.31011 3.56513C1.0107 3.26572 0.8425 2.85964 0.8425 2.43622C0.8425 2.0128 1.0107 1.60672 1.31011 1.30732C1.60951 1.00791 2.01559 0.839707 2.43901 0.839707C2.86244 0.839707 3.26852 1.00791 3.56792 1.30732L12.0002 9.74224L20.4351 1.30599C20.7345 1.00658 21.1406 0.838379 21.564 0.838379C21.9874 0.838379 22.3935 1.00658 22.6929 1.30599C22.9923 1.60539 23.1605 2.01147 23.1605 2.43489C23.1605 2.85832 22.9923 3.2644 22.6929 3.5638L14.258 12L22.6903 20.435Z"
                  fill="#94A3B8"
                />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
