"use client";

import { Footer } from "@/components/site/Footer";
import { Header } from "@/components/site/Header";
import { ContactsUsForm } from "@/components/site/parts/ContactUsForm";
import { Modal } from "@/components/site/parts/Modal";
import classNames from "classnames";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import { usePathname } from "next/navigation";
import React from "react";

import { MainSectionContent } from "../pages/ContactUs/types";
import ContactUsHeader from "../parts/ContactUsHeader";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const path = usePathname();
  const { t } = useTranslation("common");
  const title: MainSectionContent = t(`contactUs.title`, { returnObjects: true }) as any;
  return (
    <>
      <Header />

      <main
        className={classNames(
          "lg:min-h-[calc(100vh_-_772px)]",
          path === "/" ? "" : "pt-[106px] md:pt-[200px]",
          path === "/testimonials" ? "bg-site-background-gray" : ""
        )}>
        <div>{children}</div>
      </main>
      <Modal classModal="max-w-[95%] sm:max-w-[80%] xl:max-w-[50%] w-full max-h-fit h-[98%] sm:h-auto sm:max-h-[99%] rounded-lg bg-white pt-6 md:pt-8 p-4 md:p-8">
        <h3 className="mb-2 sm:mb-4 md:mb-8">
          <ContactUsHeader title={title} />
        </h3>
        <p className="mb-0 text-site-text-18-26 font-light md:mb-6 md:text-site-text-20-26">
          {parse(title.description)}
        </p>
        <ContactsUsForm classWrapper="!px-0" fieldsSettings={{ textAria: { rows: 2 } }} />
      </Modal>
      <Footer></Footer>
    </>
  );
}
