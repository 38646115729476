import { Loader } from "@/components/site/parts/Loader";
import MultiSelect from "@/components/site/parts/Multiselect";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React, { useState, useEffect, useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import InputMask from "react-input-mask";

import { Button } from "../Button";
import { CustomSelect } from "../CustomSelect";
import { ThankYou } from "../ThankYouPage";
import { ErrorMsg } from "./ErrorMsg";
import { IFormInput, ContactFormProps } from "./types";

declare global {
  interface Window {
    Intercom: (command: string) => void;
  }
}

declare var grecaptcha: any;
export const ContactsUsForm = ({ classWrapper, fieldsSettings }: ContactFormProps) => {
  const { setOpenModal, openModal } = useSiteContext();
  const { t } = useTranslation();
  const [emailSendeing, setEmailSending] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [solutions, setSolutions] = useState<number[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const solutionsList = [
    { id: 1, value: "simpleMSK", name: "SimpleMSK" },
    { id: 2, value: "simpleWellbeing", name: "SimpleWellbeing" },
    { id: 3, value: "simpleEAP", name: "SimpleEAP" },
    { id: 4, value: "simpleBehavioral", name: "SimpleBehavioral" },
  ];

  const style = { "--bg": "#F0950C", "--radius": "1rem" } as React.CSSProperties;

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    if (!openModal) {
      if (showThankYou) {
        setTimeout(() => {
          setShowThankYou(false);
        }, 5000);
      }
    }
  }, [showThankYou, openModal]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    getFieldState,
    formState: { errors },
  } = useForm<IFormInput>();

  useEffect(() => {
    if (currentEmail) {
      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        validateEmail(currentEmail);
      }, 800);
      setTimer(newTimer);
    } else {
      setEmailValid(false);
      setSubmitDisabled(true);
    }
  }, [currentEmail]);
  const openIntercomChat = () => {
    if (typeof window !== "undefined" && window.Intercom) {
      openModal && setOpenModal(false);
      window.Intercom("show");
    }
  };
  const validateEmail = async (email: string) => {
    // clearErrors("email");
    setIsValidating(true);
    if (email) {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_MAILGUN_URL}/address/validate?${new URLSearchParams({
            address: email,
          })}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${btoa(`api:${process.env.NEXT_PUBLIC_API_MAILGUN_KEY || ""}`)}`,
            },
          }
        );
        const data = await response.json();
        setEmailValid(data.result === "deliverable");
        setSubmitDisabled(data.result !== "deliverable");
        data.result !== "deliverable"
          ? setError("email", { type: "custom", message: "Please enter a valid email" })
          : clearErrors("email");
      } catch (error) {
        console.error("Error validating email:", error);
      } finally {
        setIsValidating(false);
      }
    }
  };
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const formData = {
      ...data,
      solutions: solutions.map((solution) => solutionsList.find((item) => item.id === solution)?.name || ""),
    };
    if (typeof grecaptcha !== "undefined") {
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(
          `${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}`,
          {
            action: "submit",
          }
        );
        setCaptchaToken(token);
        setEmailSending(true);
        try {
          const response = await fetch("/api/checkRecaptcha", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ captchaToken: token }),
          });
          const data = await response.json();
          if (data.success) {
            sendFormData(formData);
          } else {
            console.error("Error validating captcha");
          }
        } catch (error) {
          console.error("Error validating captcha:", error);
        } finally {
          setEmailSending(false);
        }
      });
    } else {
      console.error("grecaptcha not defined");
    }
  };
  const sendFormData = async (formData: any) => {
    try {
      setEmailSending(true);
      const response = await fetch("/api/sendToSlack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        reset();
        setSolutions([]);
        setEmailSending(false);
        // setOpenModal(false);
        setShowThankYou(true);
      } else {
        setEmailSending(false);
        alert("Error sending email");
        console.error("Error sending email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  const inputFocusEmail = () => {
    // formState?.errors?.email ? setSubmitDisabled(true) : setSubmitDisabled(false);
    setSubmitDisabled(true);
    getFieldState("email").isDirty ? setSubmitDisabled(true) : setSubmitDisabled(false);
    // clearErrors("email");
  };
  return (
    <div
      className={classNames(
        "relative h-fit rounded-[20px] bg-white px-6 py-4 md:px-10 md:py-6",
        classWrapper
      )}>
      <div
        className="pulse-border flex items-center gap-2.5 rounded-2xl bg-warning1 p-4 text-site-text-16-24 font-light"
        style={style}>
        <div className="shrink-0">
          <Image src="/images/icon/info-icon.svg" className="shrink-0" width={24} height={24} alt="tap" />
        </div>
        <p className="relative z-10">
          <span className="font-bold">Current members:</span> Please skip this form. We&apos;re here to
          help—give us a call at{" "}
          <a href="tel:800-644-2478">
            <span className="text-site-btn-orange">800-644-2478</span>
          </a>{" "}
          or click{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openIntercomChat();
            }}>
            <span className="text-site-btn-orange">here</span>
          </a>{" "}
          for support.
        </p>
      </div>

      {emailSendeing && <Loader classImageWrapper="h-30 w-30" classContainerWrapper="z-10" />}
      {showThankYou ? (
        <ThankYou />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames(
            "mt-4 flex flex-col gap-4 transition-all duration-300 md:mt-6",
            emailSendeing ? "opacity-50" : "opacity-100"
          )}>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="md:w-1/2">
              <input
                {...register("firstName", { required: true, maxLength: 20 })}
                placeholder={t("contactUs.contactForm.firstName")}
                className="w-full bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
              />
              {errors.firstName?.type === "required" && <ErrorMsg errorMsg="First Name is required" />}
            </div>
            <div className="md:w-1/2">
              <input
                {...register("lastName", { pattern: /^[A-Za-z]+$/i })}
                placeholder={t("contactUs.contactForm.lastName")}
                className="w-full bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
              />
              {errors.lastName && <ErrorMsg errorMsg={errors.lastName.message} />}
            </div>
          </div>
          <input
            {...register("companyName")}
            placeholder={t("contactUs.contactForm.companyName")}
            className="bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
          />
          <div className="relative w-full">
            <div className="relative">
              <input
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                onFocus={inputFocusEmail}
                onChange={(e) => setCurrentEmail((prev) => (prev !== e.target.value ? e.target.value : prev))}
                type="email"
                placeholder={t("contactUs.contactForm.email")}
                className="w-full bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
              />
              {isValidating && (
                <div className="absolute right-5 top-2/4 -translate-y-2/4">
                  <Loader classImageWrapper="h-6 w-6" classContainerWrapper="absolute" />
                </div>
              )}
            </div>
            {errors.email && <ErrorMsg errorMsg={errors?.email?.message || "Please enter a valid email"} />}
          </div>
          <div className="w-full">
            <InputMask
              {...register("phone", {
                pattern: {
                  value: /^\(\d{3}\) \d{3}-\d{4}$/,
                  message: "Please enter a valid phone number",
                },
              })}
              mask={"(999) 999-9999"}
              placeholder={t("contactUs.contactForm.phone")}
              type="phone"
              className="w-full bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
            />
            {errors.phone && <ErrorMsg errorMsg={errors.phone.message} />}
          </div>
          {/* <CustomSelect
            name="solutions"
            control={control}
            options={[
              { value: "simpleMSK", label: "SimpleMSK" },
              { value: "simpleWellbeing", label: "SimpleWellbeing" },
              { value: "simpleEAP", label: "SimpleEAP" },
              { value: "simpleBehavioral", label: "SimpleBehavioral" },
            ]}
            placeholder={t("contactUs.contactForm.solutions")}
            error={errors.solutions?.message}
          /> */}
          <MultiSelect
            placeholder={t("contactUs.contactForm.solutions")}
            id="multiSelect"
            value={solutions}
            onChange={(solutions: number[]) => setSolutions(solutions)}
            dataList={solutionsList}
            title=""
          />
          <CustomSelect
            name="implementations"
            control={control}
            options={[
              { value: "asSoon", label: "As soon as possible" },
              { value: "next6months", label: "Within the next 6 months" },
              { value: "next12months", label: "Within the next 12 months" },
            ]}
            placeholder={t("contactUs.contactForm.implementations")}
            error={errors.solutions?.message}
          />
          <CustomSelect
            name="demo"
            control={control}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            placeholder={t("contactUs.contactForm.demo")}
            error={errors.solutions?.message}
          />
          <textarea
            {...register("description")}
            rows={isMobile ? 2 : fieldsSettings?.textAria.rows || 6}
            placeholder={t("contactUs.contactForm.description")}
            className="mb-4 w-full bg-site-background-gray5 px-6 py-3 text-site-text-16-24 placeholder:text-site-text-16-24 placeholder:font-light focus-visible:outline-1 focus-visible:outline-orange-400 focus-visible:ring-0"
          />
          <Button
            type="submit"
            classButton="group w-full flex flex-row items-center !max-h-[52px] font-light gap-1"
            size="small"
            color="orange"
            font="light"
            rounded="large"
            textSize="medium"
            aria-label="Submit"
            disabled={!emailValid || submitDisabled}>
            {t("contactUs.contactForm.button")}
            <i className="icon-arrowR relative right-0 ml-2 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1"></i>
          </Button>
        </form>
      )}
    </div>
  );
};
